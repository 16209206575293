.charity {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 14px;
    padding: 15px;
    background-color: #eee;
}

.centre {
    display: flex;
    justify-content: center;
    padding-bottom: 5px;
}
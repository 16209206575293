.strip {
display: flex;
justify-content: space-between;
color: #444;
width: 100%;
padding: 10px 4%;
font-size: larger;
background-color: #eee;
}

.link {
color: #444;
text-decoration: none;
padding: 4px;
margin: 10px;
border-color: #D0F0C0;
border-width: 1px;
border-style: solid;
transition: 200ms;
}

.link:hover {
    color: #000;
    border-color: #444;
}

.link:hover, .selected:hover, button:hover {
    background-color: #efefef;
}

.selected {
    color: #000;
    text-decoration: none;
    padding: 4px;
    margin: 10px;
    /* border-color: #444;
    border-width: 1px; */
    border-style: solid;
    transition: 200ms;
    background-color: white;
    }

button {
    font-size: 19px;    
    border: none;
    background-color: #D0F0C0;
}

#burger-items {  
    display: block;
    position: fixed;
    width: 20vw;
    right: -10vw;
    top: 8vh;
    z-index: 3;
    background-color: #efefef;
    padding: 10px;
    /* text-align: right; */
    box-shadow: -3px 3px 3px 3px grey; 
    transition: transform .5s cubic-bezier(0, .52, 0, 1);
  }
  
  #burger-items hr {
    color: #eee;
    margin-bottom: 0;
  }
  
  .show {
    transform: translate3d(-10vw,0,0);
  }
  
  .hide {
    transform: translate3d(20vw,0,0);
  }
  
  .burger-menu {
    /* margin-left: 80%; */
    width: 20%;
    /* display:flex;
    justify-content: flex-end; */
  }
  
  .ddi {
    display: block;
    margin: 0;
    padding-top: 8px;
  }

  .ddi span {
    padding-left: 8px;
  }
  
  .ddi:hover {
    background-color: #ffc;
  }

  .burger-container {
    display: inline-block;
  }

  .logInOut {
    display: inline-block;
    vertical-align: top;
    padding-top: 5%;
  }

  .sel {
    background-color: #ffc;
  }

  .nav-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  



